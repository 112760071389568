// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TxOLMa{display:flex;gap:10px}.TxOLMa,.NMk5S9{margin-top:10px}.EGn3xi{color:darkred;cursor:pointer}.h4tguh+.h4tguh{margin-top:5px}.lIntBi{display:flex;gap:5px}.lIntBi+.lIntBi{margin-top:5px}.HDx4x8{margin-bottom:10px}.w3Qs6g{color:blue;cursor:pointer;margin-top:10px}.qp7D4g{box-sizing:border-box;padding:10px}.zoo38b{color:blue;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/app/styles.less"],"names":[],"mappings":"AAAA,QACE,YAAa,CACb,QAEF,CACA,gBAFE,eAIF,CACA,QAEE,aAAc,CADd,cAEF,CACA,gBACE,cACF,CACA,QACE,YAAa,CACb,OACF,CACA,gBACE,cACF,CACA,QACE,kBACF,CACA,QAGE,UAAW,CADX,cAAe,CADf,eAGF,CACA,QAEE,qBAAsB,CADtB,YAEF,CACA,QAEE,UAAW,CADX,cAEF","sourcesContent":[".addItem {\n  display: flex;\n  gap: 10px;\n  margin-top: 10px;\n}\n.button {\n  margin-top: 10px;\n}\n.delete {\n  cursor: pointer;\n  color: darkred;\n}\n.field + .field {\n  margin-top: 5px;\n}\n.item {\n  display: flex;\n  gap: 5px;\n}\n.item + .item {\n  margin-top: 5px;\n}\n.label {\n  margin-bottom: 10px;\n}\n.link {\n  margin-top: 10px;\n  cursor: pointer;\n  color: blue;\n}\n.main {\n  padding: 10px;\n  box-sizing: border-box;\n}\n.update {\n  cursor: pointer;\n  color: blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addItem": `TxOLMa`,
	"button": `NMk5S9`,
	"delete": `EGn3xi`,
	"field": `h4tguh`,
	"item": `lIntBi`,
	"label": `HDx4x8`,
	"link": `w3Qs6g`,
	"main": `qp7D4g`,
	"update": `zoo38b`
};
export default ___CSS_LOADER_EXPORT___;
