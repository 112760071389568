import {VoidRedux} from '@voidwalkers/void-redux';

import localForage from 'localforage';
import {composeWithDevTools} from 'redux-devtools-extension';
import {put, select, takeLatest} from 'redux-saga/effects';

const voidRedux = new VoidRedux({
  createDucks(create, defaultDucks) {
    return [
      create({
        apiWrapperOptions: {
          default: {
            collectionName: 'items'
          }
        },
        name: 'items',
        reducerOptions: {
          getIdFromEntity: entity => entity._id
        },
        subscription: {
          actionPattern: [defaultDucks.currentUser.actions.setItem.actionType.ACTION],
          hasStateChanged: (state, previousState) => state?.currentUser?.item !== previousState?.currentUser?.item,
          shouldClearOnUnsubscribe: () => false,
          shouldSubscribe: state => state?.currentUser?.item,
          // stateSelector: state => state,
          subscriptionData: state => ({
            collectionName: 'items',
            filter: {type: 'box'},
            options: {
              projection: {
                userId: 0
              }
            }
          })
        }
      })
    ];
  },
  createSagas(ducks) {
    return [
      function * () {
        yield takeLatest(ducks.currentUser.actions.initialize.actionType.ACTION, function * () {
          const loaderContainer = document.querySelector('.loader-container');

          if (!loaderContainer) {
            return;
          }

          loaderContainer.parentNode.removeChild(loaderContainer);
        });
        yield takeLatest(ducks.currentUser.actions.setItem.actionType.ACTION, function * ({payload}) {
          const currentUser = yield select(state => state.currentUser.item);

          if (!currentUser) {
            yield put(ducks.items.actions.clear());

            return;
          }

          yield put(ducks.items.actions.read({
            clear: true,
            filter: {type: 'box'},
            options: {
              sort: {
                name: -1
              }
            }
          }));
        });
      }
    ];
  },
  project: {
    slug: 'test',
    tokenPublic: '6jAAVSlT2FT8UJinFZtBNXGDhjQQXGtt19i4dCB2SqDebIpIssATq41e81sEMk26ppN+RBoQ6ODiKY7vge608w=='
  },
  reduxCompose: composeWithDevTools,
  reduxPersistConfig: {
    storage: localForage,
    whitelist: []
  }
});

export default voidRedux;
