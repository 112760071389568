import React from 'react';
import {createRoot as createReactRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';

import App from '#src/components/app/index.js';

import voidRedux from '#src/lib/redux.js';

import '#src/css/index.less';

const reactRoot = createReactRoot(document.getElementById('app'));

reactRoot.render((
  <Provider store={voidRedux.store}>
    <PersistGate loading={null} persistor={voidRedux.persistor}>
      <App/>
    </PersistGate>
  </Provider>
));
