import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import voidRedux from '#src/lib/redux.js';

import styles from './styles.less';

function _stringifyItem({deleteError, isDeleting, isUpdating, updateError, userId, ...rest}) {
  return JSON.stringify(rest);
}

const App = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.currentUser.item);
  const {items, hasFetched} = useSelector(state => state.items);

  const [email, setEmail] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [itemDataString, setItemDataString] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationToken, setVerificationToken] = useState('');

  const handleSignIn = () => {
    /* voidRedux.voidClient.auth.signInCustom({email, password}).then(() => {
      setEmail('');
      setPassword('');
    }).catch(err => console.error(err)); */
    voidRedux.voidClient.auth.signInEmail(email, password).then(() => {
      setEmail('');
      setPassword('');
    }).catch(err => console.error(err));
  };

  const handleSignUp = () => {
    /* voidRedux.voidClient.auth.signUpCustom({email, password}).then(() => {
      setEmail('');
      setPassword('');
      setIsSignUp(false);
    }).catch(err => console.error(err)); */
    voidRedux.voidClient.auth.signUpEmail(email, password).then(() => {
      setEmail('');
      setPassword('');
      setIsSignUp(false);
    }).catch(err => console.error(err));
  };

  const handleSignOut = () => {
    voidRedux.voidClient.auth.signOut(true).catch(err => console.error(err));
  };

  const handleAddItem = () => {
    let itemData = null;

    try {
      itemData = JSON.parse(itemDataString);
    } catch {
      alert('Invalid item data');

      return;
    }

    dispatch(voidRedux.ducks.items.actions.create({
      data: {
        ...itemData,
        userId: currentUser.id
      }
    }));

    setItemDataString('');
  };

  const handleDeleteItem = id => () => {
    dispatch(voidRedux.ducks.items.actions.delete({
      filter: {_id: id}
    }));
  };

  const handleUpdateItem = (id, d) => () => {
    dispatch(voidRedux.ducks.items.actions.update({
      data: {
        $inc: {
          count: d
        }
      },
      filter: {_id: id}
    }));
  };

  const handleFileChange = e => {
    const [file] = e.target.files;

    const bucket = voidRedux.voidClient.storage.bucket('user-files');

    const prefix = [currentUser.id];
    const {name} = file;

    bucket.listFiles({name, prefix}).then(list => {
      if (!list.some(f => f.name === name)) {
        return bucket.createFile(file, name, prefix);
      }
    }).then(result => {
      console.log(result);
    }).catch(err => console.error(err));
  };

  const handleSendVerificationEmail = () => {
    voidRedux.voidClient.auth.sendVerificationEmail().then(data => {
      console.log('Email issued', data);

      setVerificationToken(data.token);
    }).catch(err => console.error(err));
  };

  const handleVerifyEmail = () => {
    voidRedux.voidClient.auth.verifyEmail(verificationToken, verificationCode).then(data => {
      console.log('Email verified', data);

      setVerificationToken('');
      setVerificationCode('');
    }).catch(err => console.error(err));
  };

  return (
    <main className={styles.main}>
      {
        currentUser ? (
          <div>
            <div style={{fontWeight: 700, marginBottom: '10px'}}>
              Current user:
            </div>
            <div style={{whiteSpace: 'pre', marginBottom: '20px'}}>
              {JSON.stringify(currentUser, null, 2)}
            </div>
            <div>
              {
                hasFetched ? (
                  <>
                    <div className={styles.label}>
                      Items:
                    </div>
                    <div>
                      {
                        items.map(item => (
                          <div key={item._id} className={styles.item}>
                            <div>
                              {_stringifyItem(item)}
                            </div>
                            <div className={styles.update} onClick={handleUpdateItem(item._id, -1)}>
                              [-]
                            </div>
                            <div className={styles.update} onClick={handleUpdateItem(item._id, 1)}>
                              [+]
                            </div>
                            <div className={styles.delete} onClick={handleDeleteItem(item._id)}>
                              Delete
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    <div className={styles.addItem}>
                      <input
                        type="text"
                        placeholder="Put JSON here"
                        value={itemDataString}
                        onChange={e => setItemDataString(e.target.value)}
                      />
                      <button onClick={handleAddItem}>Add Item</button>
                    </div>
                  </>
                ) : 'Loading items'
              }
            </div>
            <button className={styles.button} onClick={handleSignOut}>Sign Out</button>
            <div style={{marginTop: '20px'}}>
              <input type="file" onChange={handleFileChange}/>
            </div>
            {
              !currentUser.auth.providers.email?.is_email_verified && (
                <div style={{marginTop: '20px'}}>
                  <div className={styles.field}>
                    <button onClick={handleSendVerificationEmail}>Send verification email</button>
                  </div>
                  <div className={styles.field}>
                    <input type="text" value={verificationToken} onChange={e => setVerificationToken(e.target.value)}/>
                  </div>
                  <div className={styles.field}>
                    <input type="text" value={verificationCode} onChange={e => setVerificationCode(e.target.value)}/>
                  </div>
                  <div className={styles.field}>
                    <button onClick={handleVerifyEmail}>Verify email</button>
                  </div>
                </div>
              )
            }
          </div>
        ) : (
          <div>
            <div className={styles.field}>
              <input type="text" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>
            <div className={styles.field}>
              <input type="password" value={password} onChange={e => setPassword(e.target.value)}/>
            </div>
            <button className={styles.button} onClick={isSignUp ? handleSignUp : handleSignIn}>
              {isSignUp ? 'Sign Up' : 'Sign In'}
            </button>
            <div className={styles.link} onClick={() => setIsSignUp(!isSignUp)}>
              {isSignUp ? 'Sign In' : 'Sign Up'}
            </div>
          </div>
        )
      }
    </main>
  );
};

export default App;
